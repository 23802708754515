import { useEffect } from "react";

const AppStoreRedirect = () => {
  useEffect(() => {
    window.location.replace("https://apps.apple.com/us/app/honest-stream-ask-me-anything/id6464457576");
  }, []);

  return null;
};

export default AppStoreRedirect;
