import React from "react";
import Logo from "../assets/logo.png";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="home">
      <img src={Logo} alt="logo" />
      <Link style={{ textDecoration: "none" }} target="_blank" rel="noreferrer" to="https://apps.apple.com/us/app/honest-link/id6464457576">
        <div textDecoration="none" className="button">
          Send Anonymous Texts&nbsp;&nbsp;➜
        </div>
      </Link>
      <div style={{ position: "absolute", bottom: 35, color: "white", fontWeight: "600", fontSize: 17 }}>
        <a href="/privacy-policy" style={{ color: "white", textDecoration: "underline" }}>
          Privacy
        </a>
        <span style={{ margin: "0 10px", fontSize: 20, fontWeight: "700" }}> - </span>
        <a href="/terms-of-use" style={{ color: "white", textDecoration: "underline", fontSize: 17 }}>
          Terms
        </a>
      </div>
    </div>
  );
};

export default Home;
